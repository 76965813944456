<template>
    <div id="button-up">
       <i class="fas fa-chevron-up"></i>
    </div>
</template>

<script>
export default {
    mounted(){
        document.getElementById("button-up").addEventListener("click", scrollUp);

        function scrollUp(){

            const currentScroll = document.documentElement.scrollTop;

            if (currentScroll > 0){
                window.scrollTo (0, 0);
            }
        }

        const buttonUp = document.getElementById("button-up");

        window.onscroll = function(){

            const scroll = document.documentElement.scrollTop;

            if (scroll > 500){
                buttonUp.style.transform = "scale(1)";
            }else if(scroll < 500){
                buttonUp.style.transform = "scale(0)";
            }

        }

    }

}
</script>

<style scoped>
#button-up{
    width: 40px;
    height: 40px;
    background: #5f8ebd;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    font-size: 20px;
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    border: 4px solid transparent;
    transition: all 300ms ease;
    transform: scale(0);
}

#button-up:hover{
    transform: scale(1.1);
    border-color: rgba(0,0,0,0.1);
}
</style>