<template>
          <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <div class="container-fluid">
                <router-link to ="/" class="navbar-brand Logo" @click="scrolltop">
                 <img id="iconnavbar" src="../assets/image/Laabejaguardiana.png" alt="Logo abeja Guardiana" width="50" height="50"> 
                 </router-link>
                
                <button id="menubutton" class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fas fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav">
                        <li class="nav-item d-flex justify-content-center"><router-link to ="/" class="nav-link" @click="scrolltop" >Inicio</router-link></li>
                     
                        <li class="nav-item d-flex justify-content-center"><router-link to ="/infgen" class="nav-link" @click="scrolltop" >Dudas frecuentes</router-link></li>
                        <li class="nav-item d-flex justify-content-center"><router-link to ="/form" class="nav-link" @click="scrolltop" >Pregúntanos/Avistamientos</router-link></li>
                        <li class="nav-item d-flex justify-content-center"><router-link to ="/libreria" class="nav-link" @click="scrolltop" >Librería</router-link></li>
                        <li class="nav-item d-flex justify-content-center"><router-link to ="/dashboard" class="nav-link " v-if="userAutenticado">Administración </router-link> </li>
                    </ul>
                    
                    <ul class="navbar-nav ml-auto ">
                      <li class="nav-item d-flex justify-content-center"><router-link to ="/login" class="nav-link " v-if="!userAutenticado"  @click="scrolltop" >Iniciar Sesion</router-link> </li>
                      <li class="nav-item d-flex justify-content-center"><router-link to ="/login" class="nav-link " v-if="userAutenticado"  @click="cerraSesion" >Cerrar Sesion</router-link> </li>
                    </ul>
                </div>
                
            </div>
        </nav>
        
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters(['userAutenticado']),
    },
    methods:{
        ...mapActions(['cerraSesion']),
        scrolltop(){
              window.scrollTo(0,0);
        },
        EventoScroll(){
            const navbarShrink = function () {
                const navbarCollapsible = document.body.querySelector('#mainNav');
                if (!navbarCollapsible) {
                    return;
                }
                if (window.scrollY === 0) {
                    navbarCollapsible.classList.remove('navbar-shrink')
                } else {
                    navbarCollapsible.classList.add('navbar-shrink')
                }

            };
           navbarShrink();

            // Shrink the navbar when page is scrolled
             document.addEventListener('scroll', navbarShrink)

            const navbarToggler = document.body.querySelector('.navbar-toggler');
            const responsiveNavItems = [].slice.call(
                document.querySelectorAll('#navbarResponsive .nav-link')
            )
            responsiveNavItems.map(function (responsiveNavItem) {
                responsiveNavItem.addEventListener('click', () => {
                    if (window.getComputedStyle(navbarToggler).display !== 'none') {
                        navbarToggler.click();
                    }
                })
            })
        },
   

    },
    mounted(){
        window.addEventListener('DOMContentLoaded',this.EventoScroll)
       
    }

}
</script>

<style scoped>

.ml-auto{
  margin-left: auto;
}
#mainNav {
  min-height: 3.5rem;
  background-color: #FDBC55;
}
#mainNav .navbar-toggler {
  /* font-size: 80%; */
  padding: 0.75rem;
  color: #fff;
  border: 1px solid #fff;
}
#mainNav .navbar-toggler:focus {
  outline: none;
}
#mainNav .navbar-brand {
  color: #fff;
  font-weight: 700;
  padding: 0.9rem 0;
}
#mainNav .navbar-nav .nav-item:hover {
  color: fade(#fff, 80%);
  outline: none;
  background-color: transparent;
}
#mainNav .navbar-nav .nav-item:active, #mainNav .navbar-nav .nav-item:focus {
  outline: none;
  background-color: transparent;
}
.navbar-light .navbar-nav .nav-link {
  color:#fff;
}
.navbar-light .navbar-nav .nav-link:hover {
   background-color: rgba(156, 156, 156, 0.438);
  color:black;
}

@media(max-width: 992px) {
  #iconnavbar{
    width: 80px;
    height: 80px;
  }
  #menubutton{
    width: 100px;
    height: 80px;
    font-size: 20px;
  }
}
@media (min-width: 992px) {

  #mainNav {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
  }
  #mainNav .navbar-brand {
    padding: 0.5rem 0;
    color: #000;
  }
  #mainNav .nav-link {
    transition: none;
    padding:auto; /*2rem 1.5rem; */
  }

  #mainNav .nav-link:active {
    color: #fff;
  }
  #mainNav.navbar-shrink {
    background-color: #FDBC55;
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #fff;
  }
    #mainNav.navbar-shrink .nav-link{
    color: #fff;
  }
  #mainNav.navbar-shrink .nav-link .navbar-brand{
    color: #fff;
    padding: 1.5rem 1.5rem 1.25rem;
    border-bottom: 0.25rem solid transparent;
  }
  #mainNav.navbar-shrink .nav-link:hover {
   
    color: black;
  }
  #mainNav.navbar-shrink .nav-link:active {
    color: #000;
  }
  #mainNav.navbar-shrink .nav-link.active {
    color: #000;
    outline: none;
    border-bottom: 0.25rem solid #000;
  }
}
</style>