<template>
          <section class="contact-section">
            <div class="container px-4 px-lg-5">
                <div class="social d-flex justify-content-center">
                    <!-- <a class="mx-2" href="#!"><i class="fab fa-twitter"></i></a> -->
                    <a class="mx-2" href="https://www.facebook.com/LaAbejaGuardianaMX" target="-blank"><i class="fab fa-facebook-f"></i></a>
                    <a class="mx-2" href="https://www.instagram.com/laabejaguardiana/" target="-blank"><i class="fab fa-instagram"></i></a>
                </div>
            </div>
        </section>
        <footer class="footer small text-center text-white-50">
            <div class="fw-bold fs-4 text-white">Siguenos a través de nuestras redes sociales.</div>
            <div class="container px-4 px-lg-5 text-white">La Abeja Guardiana <br/> &copy;2021</div>
            
        </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    padding: 16px;
}
.contact-section {
  background-color: #FDBC55;
}
.contact-section .card {
  border: 0;
  border-bottom: 0.25rem solid #64a19d;
}
.contact-section .card h4, .contact-section .card .h4 {
  font-size: 0.8rem;
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
.contact-section {
  margin-top: 2rem;
}
.contact-section .social a {
  text-align: center;
  height: 3rem;
  width: 3rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  line-height: 3rem;
  color: #fff;
}
.contact-section .social a:hover {
  color: black;
}
.contact-section .social a:active {
  color: #fff;
}
.footer{
  background-color: #FDBC55;
}
</style>