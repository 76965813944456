<template>
  
    <Navbar/>
    <RetornoArriba/>
    <div class="">
      <router-view/>
    </div>
    
</template>
<script>
import { mapActions } from "vuex";
import Navbar from './components/Navbar.vue'
import RetornoArriba from './components/RetornoArriba.vue'
export default {
  components:{
    Navbar,RetornoArriba
  },
    methods:{
    ...mapActions(['cargarLocalStorage'])
  },
  created(){
    this.cargarLocalStorage()
  },

}
</script>
<style>

</style>
