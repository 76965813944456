import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/infgen',
    name: 'IngGen',
    component: () => import(/* webpackChunkName: "about" */ '../views/IngGen.vue')
  },
  {
    path: '/form',
    name: 'SelecForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/SelecForm.vue')
  },
  {
    path: '/libreria',
    name: 'Libreria',
    component: () => import(/* webpackChunkName: "about" */ '../views/Libreria.vue')
  }
  ,
  {
    path: '/dashboard',
    name: 'DashBoard',
    component: () => import(/* webpackChunkName: "about" */ '../views/DashBoard.vue'),
    meta:{rutaProtegida:true}
  }
  ,
  {
    path: '/listapregun',
    name: 'ListaPreguntas',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListaPreguntas.vue'),
    meta:{rutaProtegida:true}
  }
  ,
  {
    path: '/listavista',
    name: 'ListaAvistamientos',
    component: () => import(/* webpackChunkName: "about" */ '../views/ListaAvistamientos.vue'),
    meta:{rutaProtegida:true}
  }
  ,
  {
    path: '/mapa',
    name: 'Mapa',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mapa.vue'),
    meta:{rutaProtegida:true}
  }
  ,
  {
    path: '/analitic',
    name: 'Analitic',
    component: () => import(/* webpackChunkName: "about" */ '../views/Analitic.vue'),
    meta:{rutaProtegida:true}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

})
router.beforeEach((to, from, next)=>{
  //console.log(to.meta.rutaProtegida)
  if(to.meta.rutaProtegida){
    if(store.getters.userAutenticado){
      next()
    }else{
      next('/login')
    }
  }else{
    next()
  }
})

export default router
