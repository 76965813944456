import { createStore } from 'vuex'
import router from '../router'
export default createStore({
  state: {
    log:false,
    user:null,
    error:null,
    analisis:null,
    
  },
  mutations: {
    setlog(state,payload){
      state.log=payload
    },
    setError(state,payload){
      state.error=payload
    },
    setUser(state,payload){
      state.user= payload
    },
    userret(state){
        return state.user
    },
     cargaranalisis(state,payload){
      state.analisis=payload
    },
  },
  actions: {
    //--------------------------------------------------------consumo de api
    //https://www.apiabeja.laabejaguardiana.com
    //http://127.0.0.1:8000/api/avistamiento
    //Cerrar sesion ------------------------------------------------------------
    async cerraSesion({commit,state}){
      try {
        const res = await fetch('https://www.apiabeja.laabejaguardiana.com/api/logout',{
          method: 'POST',         
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          }
        })
        const userDB = await res.json()
        // console.log(userDB)
      } catch (error) {
        console.log(error)
      }
      router.push('/login')
      commit('setUser',null) 
      localStorage.removeItem('usuario')
    },
    //login -------------------------------------------------------------
    async ingresoUsuario({commit},Captura){
      try {
        commit('setlog',true)
        const res = await fetch('https://www.apiabeja.laabejaguardiana.com/api/login',{
          method: 'POST',
          headers:{
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(Captura)
        })
        const userDB = await res.json()
        //console.log(userDB)
        if(userDB.error){
        //   // console.log(userDB.error)
        return commit('setError',userDB)
        //  return console.log(userDB.error)
        }
        commit('setUser',userDB)
        // console.log(userDB)
        commit('setError', null)
        commit('setlog',false)
        router.push('/dashboard')
        localStorage.setItem('usuario',JSON.stringify(userDB))
        
      } catch (error) {
        console.log(error)
      }
    },
    //post avistamiento----------------------------------------------------------------------
    async envioavistamiento({commit,state},Captura){
      try {
        const res = await fetch(`https://www.apiabeja.laabejaguardiana.com/api/avistamiento`,
        {
          method:'POST',
          headers:{
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(Captura)
        });
        return res;
        //  console.log(dataDB)
      } catch (error) {
        console.log(error)
      }
      
    },
    //post preguntas --------------------------------------------------------------------------
    async enviopreguntas({commit,state},Captura){
      try {
        const res = await fetch(`https://www.apiabeja.laabejaguardiana.com/api/pregunta`,
        {
          method:'POST',
          headers:{
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(Captura)
        });
        return res;
      } catch (error) {
        console.log(error)
      }
    },
    //lista preguntas dashboard -----------------------------------------------------------------------
    async listapreg({commit,state}){
      try {
        const res = await fetch('https://www.apiabeja.laabejaguardiana.com/api/verpreguntas',{
          method: 'GET',         
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          }
        })
        const dataDB = await res.json()
        if(dataDB.error){
          if(dataDB.error=='Usuario no autenticado.'){
            router.push('/login')
            commit('setUser',null) 
            commit('setError', dataDB)
            localStorage.removeItem('usuario')
          }
          return
          }

        // commit('setpagipregu', Math.ceil(arraypreguntas.length/50))
        commit('setError', null)
        // commit('cargapreguntas',arraypreguntas)
        return dataDB.data;
        // console.log(dataDB)
      } catch (error) {
        console.log(error)
      }
    },
    // lista avisamientos dashboard-------------------------------------------------------------------------------
    async listaavistamiento({commit,state}){
      try {
        const res = await fetch('https://www.apiabeja.laabejaguardiana.com/api/avistamientos',{
          method: 'GET',         
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          }
        })
        const dataDB = await res.json()
        // console.log(dataDB.data);
        if(dataDB.error){
          if(dataDB.error=='Usuario no autenticado.'){
            router.push('/login')
            commit('setUser',null) 
            commit('setError', dataDB)
            localStorage.removeItem('usuario')
          }
          return
          }
        // console.log(dataDB.data.data);
        commit('setError', null)
        return dataDB.data;
      } catch (error) {
        console.log(error)
      }
    },  
    // delete pregunta----------------------------------------------------------------
    async deletepregunta({commit,state},id){
      try {
        const res = await fetch(`https://www.apiabeja.laabejaguardiana.com/api/verpreguntas/${id}`,{
          method:'DELETE',
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          },
        })
        const dataDB = await res.json()
        if(dataDB.error){
          console.log(userDB.error)
          return commit('setError',dataDB)
          //  return console.log(userDB.error)
          }
        commit('setError', null)
        return dataDB;
      } catch (error) {
        console.log(error)
      }

    },
    // actualizar especie --------------------------------------------------------------------------
    async actualizarespecie({commit,state},avi){
      try {
        const res = await fetch(`https://www.apiabeja.laabejaguardiana.com/api/updaavista/${avi.id}`,{
          method:'POST',
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          },
          body: JSON.stringify(avi)
        })
        const dataDB = await res.json()
        if(dataDB.error){
          if(dataDB.error=='Usuario no autenticado.'){
            router.push('/login')
            commit('setUser',null) 
            commit('setError', dataDB)
            localStorage.removeItem('usuario')
          }
          return
          }
          return dataDB;
        commit('setError', null)
      } catch (error) {
        console.log(error)
      }

    },
    // eliminar avistamiento --------------------------------------------------------------------------
    async eliminaravistamiento({commit,state},id){
      try {
        const res = await fetch(`https://www.apiabeja.laabejaguardiana.com/api/avistamientos/${id}`,{
          method:'DELETE',
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          },
        })
        const dataDB = await res.json()
        if(dataDB.error){
          console.log(userDB.error)
          return commit('setError',dataDB)
          //  return console.log(userDB.error)
          }
        commit('setError', null)
        return dataDB;
      } catch (error) {
        console.log(error)
      }

    },
    // metodo para consumir el modulo de analisis
    async moduloanalisis({commit,state},anio){
      try {
        const res = await fetch(`https://www.apiabeja.laabejaguardiana.com/api/moduloanalisis/${anio}`,{
          method:'POST',
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          },
        })
        const dataDB = await res.json()
        if(dataDB.error){
          if(dataDB.error=='Usuario no autenticado.'){
            router.push('/login')
            commit('setUser',null) 
            commit('setError', dataDB)
            localStorage.removeItem('usuario')
          }
          return
          //  return console.log(userDB.error)
          }
        // console.log(dataDB.mensaje)
        commit('cargaranalisis',dataDB) 
      } catch (error) {
        console.log(error)
      }

    },
    //metodo para actualizar respuesta
    async actualizarrespuesta({commit,state},pregun){

      try {
        const res = await fetch(`https://www.apiabeja.laabejaguardiana.com/api/EnviarRespuesta`,{
          method:'POST',
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.user.access_token
          },
          body: JSON.stringify(pregun)
        })
        const dataDB = await res.json()
        if(dataDB.error){
          if(dataDB.error=='Usuario no autenticado.'){
            router.push('/login')
            commit('setUser',null) 
            commit('setError', dataDB)
            localStorage.removeItem('usuario')
            return
          }     
        }     
          commit('setError', null)
          
          return dataDB;
          // commit('updaterespuestas',pregun)  
        
      } catch (error) {
        console.log(error)
      }
    },
    //metodo locales --------------------------------------------------------------------------
    // cargar local storage
    cargarLocalStorage({commit,state}){
        if(localStorage.getItem('usuario')){
          var SOneDateFrom = JSON.parse(localStorage.getItem('usuario'));
          commit('setUser',SOneDateFrom)
          // console.log(SOneDateFrom.access_token+" cargado")
        }else{
          // console.log("no busca")
          return commit('setUser',null)
        }
    },
  },
  getters:{
    userAutenticado(state){
      return !!state.user
    }
  },

  modules: {
  }
})
