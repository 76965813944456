<template>
        <header class="masthead">
            <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                <div class="d-flex justify-content-center">
                    <div class="text-center">
                        <h1 class="mx-auto my-0 text-uppercase">La abeja Guardiana</h1>
                      </div>
                </div>
            </div>
        </header>
        <section class="projects-section">
            <div class="container px-4 px-lg-5">
                <div class="text-center" id="Titulos"> <h1 class="mt-3 ">¿Cuál es nuestra labor?</h1>
                    <div class="featured-text-down mb-2"></div>
                </div>

                <!-- Project One Row-->
                <div class="row gx-0 mb-5 mb-lg-0 justify-content-center ">
                    <div class="col-lg-6 d-flex justify-content-center"><img class=" w-75 logoAbeja" src="../assets/image/Laabejaguardiana.png" alt="..." /></div>
                    <div class="col-lg-6 my-auto ">
                        <div class="bg-abeja text-center h-100 project p-4 borderCir">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 text-center ">
                                    
                                    <p class="mb-0 text-white pb-3">La Abeja Guardiana imparte talleres para niños, realiza recorridos guiados, organiza eventos artísticos, ofrece pláticas en escuelas públicas y privadas sobre la importancia de los polinizadores y de las abejas en particular. De igual modo la Abeja Guardiana ha iniciado el proyecto de conservación de colonias de abejas con el fin de asegurar la supervivencia de la especie Melipona beecheii en el Parque Ecológico Metropolitano del Sur Yumtsiil.
                                      Como parte de sus acciones el colectivo organizó con gran éxito el Primer Encuentro de Meliponicultores de Mérida y sus Alrededores el día 15 de Junio de 2019 en la videosala del Centrol Cultural de Mérida Olimpo, este evento permitió el intercambio de experiencias entre los principales cuidadores de las abejas meliponas. De igual modo, este Primer Encuentro permitió que la gente conociera de primera mano los testimonios y la cultura de esta noble actividad que es el cuidado de abejas meliponas. 
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="text-center"> <h1 class="mt-3">¿Quiénes somos?</h1>
                    <div class="featured-text-down mb-2"></div>
                </div>



                <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                    <div class="col-lg-6 my-auto">
                        <div class="bg-abeja text-center h-100 project p-4 borderCir">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 text-center">
                                    <p class="mb-0 text-white pb-3">La Abeja Guardiana es un grupo interdisciplinario de estudiantes de ingeniería ambiental del Tecnológico de Mérida, biólogos, investigadores científicos y artistas colaborativos interesados en difundir la importancia y cuidado de las abejas. Este grupo se estableció en mayo de 2018 y toma su nombre de las abejas encargadas de cuidar la colmena en varias especies de polinizadores, como en el género Melipona. La Abeja Guardiana tiene como punto de referencia la preservación de la especie Melipona beecheii ante el riesgo de extinción por la deforestación, la contaminación y la sobre-explotación de este valioso recurso natural y cultural. Para ello ha establecido colmenas para enseñanza e imparte talleres para todo público. </p> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6"><img class="imageresponsive bordePerso margin-iz" src="../assets/image/IMG_0194.png" alt="..." /></div>
                </div>
            </div>
          </section>
          <Footer />
</template>

<script>
// @ is an alias to /src
import Footer from "../components/Footer.vue"
export default {
  name: 'Home',
  components: {
    Footer
  },
  methods:{

  },
  
}
</script>
<style scoped>
.margin-iz{
  margin-left: 2.5%;
}
.imageresponsive{
max-width: 100%;
height: auto;
padding-top: 100px;
padding-bottom: 100px;
}
p{
    text-align: justify;
    font-size: 19px;
}
.logoAbeja{
  max-height: 75%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.borderCir{
  border-radius: 50px 50px 50px 50px;
}
.bordePerso{
border-radius:  10% / 50%;;
}

p{
  text-transform: justify;
}
.bg-abeja{
  background-color: #FDBC55;
}
.featured-text-down {
  padding: 2rem;
}
@media (min-width: 350px) {
  .featured-text-down {
    padding: 0 0 0 2rem;
    border-bottom: 0.5rem solid #FDBC55;
    margin-left: 40%;
    margin-right: 40%;
  }
}
.text-center {
  text-align: center !important;
}
h1, .h1 {
  font-family:"Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}
.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;
   /* url("../assets/bg-masthead.jpg") */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 75%, #000 100%),url("../assets/IMG_4639.jpg") ;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.masthead h1, .masthead .h1 {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.8rem;
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.3));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
          background-clip: text;
}
.masthead h2, .masthead .h2 {
  max-width: 20rem;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .masthead h1, .masthead .h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (min-width: 992px) {
  .masthead {
    height: 100vh;
    padding: 0;
  }
  .masthead h1, .masthead .h1 {
    font-size: 6.5rem;
    line-height: 6.5rem;
    letter-spacing: 0.8rem;
  }
  .masthead h2, .masthead .h2 {
    max-width: 30rem;
    font-size: 1.25rem;
  }
}
</style>